<template>
  <div class="ssoMapping">
    <div class="ssoMapping--tableTitle">{{ $t("all.workspaces") }}</div>
    <div>
      <div class="ssoMapping--attributes">
        <div class="ssoMapping--attributesTitle">{{ $t("admin.attributes") }}</div>
        <p class="ssoMapping--attributesSubtitle">{{ $t("admin.oneAttributeToMap") }}</p>
        <div class="ssoMapping--selectAttribute">
          <div class="ssoMapping--selectAttributeButtonWrapper">
            <div
              class="ssoMapping--selectAttributeButton"
              :class="{ 'ssoMapping--selectAttributeButton_disabled': !changeEnabled }"
              @click="showAttributeList = !showAttributeList"
            >
              <p class="ssoMapping--attributesValue">{{ attributeValue }}</p>
              <v-spacer></v-spacer>
              <v-icon color="#8d909f" class="dropdown-arrow pt-2">keyboard_arrow_down</v-icon>
            </div>
            <v-card v-if="showAttributeList" class="ssoMapping--attributesDropdown">
              <v-list dense light class="ssoMapping--attributesDropdownList">
                <v-list-tile
                  v-for="field in attributeFields"
                  :key="field.key"
                  class="ssoMapping--attributesDropdownItem"
                  @click="selectAttribute(field.key)"
                >
                  <v-list-tile-title>{{ field.value }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
              <div class="ssoMapping--closeAttributesDropdown" @click="showAttributeList = false"></div>
            </v-card>
          </div>
          <v-spacer></v-spacer>
          <d-button
            small
            round
            depressed
            outline
            class="ssoMapping--changeAttributeButton body-2 px-4"
            color="dBlue"
            :disabled="changeEnabled"
            light
            @click="showConfirmation = 'change-attribute'"
            >{{ $t("admin.changeAttribute") }}</d-button
          >
        </div>
      </div>

      <div v-if="madeChange" class="ssoMapping--updateMapping">
        <div class="ssoMapping--updateMappingIcon">
          <icon-base width="24" height="24" color="#4689F3">
            <d-icon-info />
          </icon-base>
        </div>
        <div class="ssoMapping--updateMappingTitleWrapper">
          <p class="ssoMapping--updateMappingTitle">{{ $t("admin.mappingValuesUpdated") }}</p>
          <p class="ssoMapping--updateMappingSubtitle">{{ $t("admin.mappingValuesUpdatedSubtitle") }}</p>
        </div>
        <v-spacer></v-spacer>
        <d-button
          small
          round
          depressed
          outline
          class="ssoMapping--updateMappingButton body-2 px-4 ml-4"
          color="dBlue"
          light
          @click="showConfirmation = 'discard-changes'"
          >{{ $t("admin.discardChanges") }}</d-button
        >
        <d-button
          small
          round
          depressed
          class="ssoMapping--updateMappingButton body-2 px-4 white--text"
          color="dBlue"
          light
          @click="showConfirmation = 'apply-changes'"
          >{{ $t("admin.applyChanges") }}</d-button
        >
      </div>

      <div class="ssoMapping--tableWrapper">
        <d-data-table
          class="ssoMapping--table"
          add-search
          local-search
          initial-sort
          :headers="tableHeader"
          :items="displayWorkspaces"
          :loading="isLoading"
          :search="searchInput"
          :placeholder="$t('analytics.searchWorkspace')"
          searchAutotest="sso-mapping-search-bar"
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-left text-capitalize" autotest="sso-mapping-workspace-name-display">
              <span class="text-no-wrap">{{ props.item.name }}</span>
            </td>
            <td class="text-xs-left" autotest="sso-mapping-workspace-group-display">
              {{ props.item.workspaceGroupName }}
            </td>
            <td class="text-xs-left" autotest="sso-mapping-attributes-display">
              <span v-if="props.item.attributeValues.length > 0">{{ props.item.attributeValues.join(", ") }}</span>
              <span v-else class="ssoMapping--tableFieldText">{{ props.item.attributeTextDisplay }}</span>
            </td>
            <td class="text-xs-right">
              <div v-if="props.item.name.toLowerCase() !== 'general'" class="ssoMapping--tableButtonsWrapper">
                <button class="mr-3" @click="editWorkspaceItem('add', props.item)">
                  <icon-base width="20" height="20" color="#52545D">
                    <d-icon-add-sm />
                  </icon-base>
                </button>
                <button v-if="props.item.attributeValues.length > 0" @click="editWorkspaceItem('remove', props.item)">
                  <icon-base width="20" height="20" color="#52545D">
                    <d-icon-remove-sm />
                  </icon-base>
                </button>
              </div>
            </td>
          </template>
          <template slot="no-data">
            <span>{{ $t("analytics.msgNoDataYet") }}</span>
          </template>
          <template slot="no-results">
            <span>{{ $t("analytics.msgNoSearchResults") }}</span>
          </template>
        </d-data-table>
      </div>
    </div>

    <d-admin-edit-values-popup
      v-if="showPopup"
      :popupType="popupType"
      :editWorkspace="editWorkspace"
      :attributeValues="attributeValues"
      @close-popup="showPopup = false"
      @add-attributes="updateAttributes"
      @remove-attributes="updateAttributes"
    ></d-admin-edit-values-popup>

    <d-change-attribute-confirmation
      v-if="showConfirmation === 'change-attribute'"
      @close-popup="showConfirmation = ''"
      @change-attribute="changeAttribute"
    ></d-change-attribute-confirmation>

    <d-discard-mapping-changes-confirmation
      v-if="showConfirmation === 'discard-changes'"
      @close-popup="showConfirmation = ''"
      @discard-changes="discardMappingChanges"
    ></d-discard-mapping-changes-confirmation>

    <d-save-mapping-changes-confirmation
      v-if="showConfirmation == 'apply-changes'"
      :changedWorkspaces="changedWorkspaces"
      @close-popup="showConfirmation = ''"
      @apply-changes="applyMappingChanges"
    ></d-save-mapping-changes-confirmation>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconAddSm from "@/components/icons/DIconAddSm.vue";
import DIconRemoveSm from "@/components/icons/DIconRemoveSm.vue";
import DAdminEditValuesPopup from "@/components/DAdmin/DAdminEditValuesPopup.vue";
import DChangeAttributeConfirmation from "@/components/DAdmin/confirmations/DChangeAttributeConfirmation.vue";
import DIconInfo from "@/components/icons/DIconInfo.vue";
import DDiscardMappingChangesConfirmation from "@/components/DAdmin/confirmations/DDiscardMappingChangesConfirmation.vue";
import DSaveMappingChangesConfirmation from "@/components/DAdmin/confirmations/DSaveMappingChangesConfirmation.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import { cloneDeep } from "lodash-es";
import {
  fetchOrganizationMappings,
  putOrganizationMappings,
  fetchMappingAttributeFields,
  fetchMappingAttributeValues,
} from "@/server/organization-mapping-server.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DAdminSsoMapping",
  components: {
    IconBase,
    DIconSearch,
    DIconPen,
    DIconAddSm,
    DIconRemoveSm,
    DAdminEditValuesPopup,
    DChangeAttributeConfirmation,
    DIconInfo,
    DDiscardMappingChangesConfirmation,
    DSaveMappingChangesConfirmation,
    DDataTable,
  },
  mixins: [MixinUser, MixinDB],
  data() {
    return {
      workspaces: [],
      workspacesWithAttribute: [],
      displayWorkspaces: [],
      attributeFields: [],
      attributeValues: [],
      mappings: [],
      selectedAttributeKey: "",
      showAttributeList: false,
      searchInput: "",
      showPopup: false,
      editWorkspace: {},
      updateMappings: [],
      tableHeader: [
        {
          text: this.$t("all.workspace"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("all.workspaceGroup"),
          align: "left",
          sortable: true,
          value: "workspaceGroupName",
        },
        {
          text: this.$t("admin.values"),
          align: "left",
          sortable: true,
          value: "valueList",
        },
        {
          text: "",
          align: "right",
          sortable: true,
        },
      ],
      popupType: "",
      changeEnabled: false,
      showConfirmation: "",
      isLoading: false,
      madeChange: false,
      changedWorkspaces: [],
    };
  },
  computed: {
    ...mapGetters("workspace", ["getter_workspaces_with_workspace_groups_name"]),
    attributeValue() {
      const field = this.attributeFields.find((field) => field.key === this.selectedAttributeKey);
      return field?.value || this.$t("admin.selectAttribute");
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions("global", ["openAlert"]),
    ...mapActions("workspaceGroups", ["getWorkspaceGroupList"]),
    async initialize() {
      this.selectedAttributeKey = this.$clientSettings?.userMappings?.groupMapping?.[0] ?? "";
      this.fetchInitialData();
    },
    async fetchInitialData() {
      this.isLoading = true;
      try {
        await Promise.all([
          this.getOrganizationMappings(),
          this.handleFetchMappingAttributeFields(),
          this.handleFetchMappingAttributeValues(),
          this.getWorkspaceGroupList({ workspacesType: "admin" }),
        ]);
        this.handleWorkspacesWithAttribute();
      } catch (error) {
        this.alertUnexpectedError();
      } finally {
        this.isLoading = false;
      }
    },
    async handleFetchMappingAttributeFields() {
      this.attributeFields = await fetchMappingAttributeFields();
    },
    async handleFetchMappingAttributeValues() {
      this.attributeValues = await fetchMappingAttributeValues({ field: this.selectedAttributeKey });
      this.attributeValues.sort();
    },
    handleWorkspacesWithAttribute() {
      this.workspaces = [...this.getter_workspaces_with_workspace_groups_name];
      this.getWorkspacesWithAttribute();
    },

    /**
     * 'workspacesWithAttribute' matches each workspace with its corresponding mapping by id.
     * Then enriches the workspace with the selected attribute's key and values from the mapping.
     */
    getWorkspacesWithAttribute() {
      const mappingsWithKey = this.mappings.reduce((acc, mapping) => ({ ...acc, [mapping.mapId]: mapping }), {});
      this.workspacesWithAttribute = this.workspaces.map((workspace) => {
        const mapping = mappingsWithKey[workspace.id];
        const attribute = mapping ? { [this.selectedAttributeKey]: mapping[this.selectedAttributeKey] } : {};
        const attributeValues = attribute[this.selectedAttributeKey] || [];
        attributeValues.sort();
        const attributeTextDisplay =
          workspace.name.toLowerCase() === "general" ? "N/A" : this.$t("admin.clickPlusToAddValues");
        return { ...workspace, attribute, attributeValues, attributeTextDisplay };
      });
      this.displayWorkspaces = cloneDeep(this.workspacesWithAttribute);
      this.displayWorkspaces.sort();
    },
    async getOrganizationMappings() {
      const { ok, data } = await fetchOrganizationMappings("groups");
      if (ok) this.mappings = data.item;
    },
    async updateAttributes(data) {
      const values = this.handleAttributeValues(data);
      const idx = this.displayWorkspaces.findIndex((workspace) => workspace.id === this.editWorkspace.id);
      idx !== -1 && (this.displayWorkspaces[idx].attributeValues = values);
      const pos = this.changedWorkspaces.findIndex((workspaceName) => workspaceName === this.editWorkspace.name);
      pos === -1 && this.changedWorkspaces.unshift(this.editWorkspace.name);
      this.madeChange = true;
    },
    handleAttributeValues(attributes) {
      const attributeValues = [...this.editWorkspace.attributeValues];
      if (this.popupType === "add") {
        return [...attributeValues, ...attributes].sort();
      }
      if (this.popupType === "remove") {
        return attributeValues.filter((value) => !attributes.includes(value));
      }
    },
    editWorkspaceItem(type, item) {
      this.popupType = type;
      this.editWorkspace = item;
      this.showPopup = true;
    },
    async selectAttribute(key) {
      this.selectedAttributeKey = key;
      this.showAttributeList = false;
      this.displayWorkspaces = [];
      this.isLoading = true;
      try {
        await this.handleFetchMappingAttributeValues();
        this.handleWorkspacesWithAttribute();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.changeEnabled = false;
      }
    },
    async applyMappingChanges() {
      this.showConfirmation = "";
      this.madeChange = false;
      try {
        const mappings = this.displayWorkspaces.map((workspace) => ({
          mapId: workspace.id,
          values: workspace.attributeValues,
        }));
        const { ok } = await putOrganizationMappings({
          type: "groups",
          attribute: this.selectedAttributeKey,
          mappings,
        });
        if (!ok) {
          return this.openAlert({ type: "error", message: this.$t("admin.alertAppliedFailed") });
        }
        this.$clientSettings.userMappings.groupMapping = [this.selectedAttributeKey];
        this.displayWorkspaces = [];
        await this.fetchInitialData();
        this.openAlert({ type: "success", message: this.$t("admin.alertAppliedSuccess") });
      } catch (error) {
        this.alertUnexpectedError();
      } finally {
        this.changedWorkspaces = [];
      }
    },
    discardMappingChanges() {
      this.showConfirmation = "";
      this.madeChange = false;
      this.changedWorkspaces = [];
      this.displayWorkspaces = cloneDeep(this.workspacesWithAttribute);
      this.openAlert({ type: "success", message: this.$t("admin.alertDiscardedSuccess") });
    },
    changeAttribute() {
      this.changeEnabled = true;
      this.showConfirmation = "";
      this.selectedAttributeKey = "";
      this.displayWorkspaces = this.workspaces.map((workspace) => {
        const attributeTextDisplay =
          workspace.name.toLowerCase() === "general" ? "N/A" : this.$t("admin.clickPlusToAddValues");
        return { ...workspace, attribute: {}, attributeValues: [], attributeTextDisplay };
      });
    },
    alertUnexpectedError() {
      this.openAlert({ type: "error", message: this.$t("alert.unexpectedError") });
    },
  },
};
</script>

<style scoped lang="scss">
.ssoMapping {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  padding-bottom: 8px;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;
  overflow: scroll;

  &--tableTitle {
    color: #3f3f3f;
    font-family: Roboto;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 20px;
  }

  &--attributes {
    width: 100%;
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
    padding: 24px;
  }

  &--attributesTitle {
    color: #3f3f3f;
    font-family: Roboto;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 12px;
  }

  &--attributesSubtitle {
    color: #919191;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 20px;
  }

  &--selectAttribute {
    position: relative;
    height: 36px;
    display: flex;
    max-width: 650px;
  }

  &--selectAttributeButtonWrapper {
    position: relative;
    width: 100%;
  }

  &--selectAttributeButton {
    position: relative;
    width: 100%;
    height: 36px;
    display: flex;
    border-bottom: 1px solid #dadada;

    &_disabled {
      pointer-events: none !important;
      opacity: 0.6;
    }
  }

  &--attributesValue {
    color: #52545d;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 36px;
  }

  &--attributesDropdown {
    width: 100%;
    top: 36px;
    max-height: 240px;
    overflow-y: auto;
    background-color: white;
    z-index: 1;

    &:hover::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
    }
  }

  &--attributesDropdownList {
    width: 100%;
  }

  &--attributesDropdownItem:hover {
    background-color: rgba(245, 245, 245) !important;
  }

  &--closeAttributesDropdown {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &--changeAttributeButton {
    margin: 0;
    text-transform: none !important;
    margin-left: 32px;
  }

  &--updateMapping {
    border: 1px solid #4689f3;
    background-color: rgba(70, 137, 243, 0.2);
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
    padding: 20px 16px;
    margin-top: 32px;
    min-height: 80px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &--updateMappingIcon {
    position: relative;
    height: 24px;
    width: 24px;
  }

  &--updateMappingTitleWrapper {
    margin-left: 16px;
    position: relative;
    width: 100%;
  }

  &--updateMappingTitle {
    color: #3f3f3f;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0;
  }

  &--updateMappingSubtitle {
    color: #8d909f;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 6px 0 0 0;
  }

  &--updateMappingButton {
    text-transform: none !important;
  }

  &--tableWrapper {
    margin-top: 32px;
    width: 100%;
    overflow: auto;

    &:hover::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
    }

    .ssoMapping--tableFieldText {
      color: rgba(82, 84, 93, 0.5);
    }
  }

  &--table {
    z-index: 0;
    height: 500px;
  }

  &--tableButtonsWrapper {
    display: flex;
    padding-top: 4px;
  }
}
</style>
